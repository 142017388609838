import {Components, Providers, Templates, Utils as DirectFormioUtils} from "formiojs";
import FormioDigitalSignatureComponent from "formio-digital-signature-component";
import CubanProductList, {template as CubanProductListTemplate} from "./components/cuban-product-list";
import QrCode, {template as QrCodeTemplate} from "./components/qrcode";
import FormioNestedHtmlElement from 'formio-nested-html-element';
import FormioLeafletMap from 'formio-leaflet-map';
import FormioDropdown from 'formio-dropdown';

const DigitalSignatureComponent = FormioDigitalSignatureComponent.DigitalSignature(Components.components.button);
Components.addComponent(DigitalSignatureComponent.schema().key, DigitalSignatureComponent);

Components.addComponent(CubanProductList.schema().key, CubanProductList);
Templates.templates.bootstrap[CubanProductList.schema().key] = CubanProductListTemplate;

Components.addComponent(QrCode.schema().key, QrCode);
Templates.templates.bootstrap[QrCode.schema().key] = QrCodeTemplate;

const FormioNestedHtmlElementComponent = FormioNestedHtmlElement.NestedHtmlElement(Components.components.panel);
Components.addComponent(FormioNestedHtmlElementComponent.schema().key, FormioNestedHtmlElementComponent);
Templates.templates.bootstrap[FormioNestedHtmlElementComponent.schema().key] = FormioNestedHtmlElement.NestedHtmlElementTemplate;

const FormioLeafletMapComponent = FormioLeafletMap.Class(Components.components.field);
Formio.registerComponent(FormioLeafletMapComponent.type, FormioLeafletMapComponent);
Templates.templates.bootstrap[FormioLeafletMapComponent.type] = FormioLeafletMap.template;

const FormioDropdownComponent = FormioDropdown.Class(Components.components.panel);
Formio.registerComponent(FormioDropdownComponent.type, FormioDropdownComponent);
Templates.templates.bootstrap[FormioDropdownComponent.type] = FormioDropdown.template;

let urlProviderFunction = Providers.providers.storage.url;
let originalUrlProviderFunction = urlProviderFunction;
urlProviderFunction = function (formio) {
    // Use function or class here https://github.com/formio/formio.js/issues/5230
    let urlProvider = originalUrlProviderFunction(formio);
    urlProvider.deleteFile = function (fileInfo) {
        // Only DS backend checks and deletes unused files on submit
        return Promise.resolve()
    };

    return urlProvider;
};

Providers.providers.storage.url = urlProviderFunction;

/**
 * We need to override this as the default one is broken up
 * @param string
 * @param data
 * @returns {*}
 */
Components.components.htmlelement.prototype.interpolate = function (string, data) {
    if (!string) {
        return string
    }
    return DirectFormioUtils.interpolate(string, this.evalContext(data));
}

Components.components.base.prototype.tt = Components.components.base.prototype.t;
Components.components.base.prototype.t = function (text) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (params) {
        if (params._userInput) {
            params._userInput = false;
        }
        params.nsSeparator = '';
    }

    return this.tt(text, params);
}

Templates.templates.bootstrap.modalPreview.form = `<button lang="en" class="btn btn-primary btn-md open-modal-button form-control {{ctx.component.customClasses?.join(' ')}}" ref="openModal">
  {{ ctx.t(ctx.component.title) }}
</button>`;

Templates.templates.bootstrap.wizardHeader.form = `<nav aria-label="navigation" id="{{ ctx.wizardKey }}-header">
        <ul class="pagination">
            {% ctx.panels.forEach(function(panel, index) {%}
                <li class="page-item{{ctx.currentPage === index ? ' active' : ''}}" style="cursor: pointer;">
                <span class="page-link" ref="{{ctx.wizardKey}}-link">
            {{ctx.t(panel.title)}}
            {% if (panel.tooltip && ctx.currentPage === index) {%}
                <i ref="{{ctx.wizardKey}}-tooltip" class="{{ctx.iconClass('question-sign')}} text-muted" data-tooltip="{{panel.tooltip}}"></i>
            {%} %}
                </span>
                </li>
            {%}) %}
        </ul>
    </nav>`;

Templates.templates.bootstrap.wizardHeader.form = `<nav aria-label="navigation" id="{{ ctx.wizardKey }}-header">
        <ul class="pagination">
            {% ctx.panels.forEach(function(panel, index) {%}
                <li class="page-item{{ctx.currentPage === index ? ' active' : ''}}" style="cursor: pointer;">
                <span class="page-link" ref="{{ctx.wizardKey}}-link">
            {{ctx.t(panel.title)}}
            {% if (panel.tooltip && ctx.currentPage === index) {%}
                <i ref="{{ctx.wizardKey}}-tooltip" class="{{ctx.iconClass('question-sign')}} text-muted" data-tooltip="{{panel.tooltip}}"></i>
            {%} %}
                </span>
                </li>
            {%}) %}
        </ul>
    </nav>`;
